<template>
  <tfoms-panel title="Условия и виды оказания МП" small v-bind="$attrs">
    <div
      class="analysis-tfoms_candition-table analysis-tfoms__table analysis-tfoms__base-table"
    >
      <base-table-full
        :dataSource="data"
        :headers="computedHeaders"
        hide-default-footer
        notShowSelect
        nested
        @click:row="clickRow"
        not-filter
        sort-by="percent"
        :sort-desc="true"
        :exclude-settings-actions="['table', 'group']"
      >
        <template slot="item.percent2" slot-scope="{ item }">
          <span
            v-if="!isSancation"
            :class="{
              'red--text': item.percent < 100,
              'green--text': item.percent > 100,
            }"
            >{{ item.percent }}%
          </span>
          <template v-else>{{ item.percent | numberSplit }}</template>
        </template>
      </base-table-full>
    </div>
  </tfoms-panel>
</template>
<script>
import BaseTableFull from "@/components/base/BaseTableFull";
export default {
  components: {
    BaseTableFull,
    tfomsPanel: () => import("@/components/analysis/panel.vue"),
  },

  inject: ["tfomsParams"],
  props: {
    value: {
      type: Object,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "primary",
    },
    isSancation: {
      type: Boolean,
      default: false,
    },
    isNoPayment: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { text: "Показатель", value: "Name" },
        {
          text: "План",
          value: "plan",
          align: "center",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: "Факт",
          value: "fact",
          align: "center",
          displayText: (e) => this.$options.filters.numberSplit(e),
        },
        {
          text: "Выполнение плана",
          value: "percent",
          align: "center",
          dataType: "numberBar",
          options: {
            background: (head, value) => {
              return value < 100 ? "error" : "success";
            },
            append: "%",
          },
        },
      ],
      headers2: [
        { text: "Показатель", value: "Name" },
        {
          text: "Неоплата",
          value: "plan",
          displayText: (e) => this.$options.filters.numberSplit(e),
          align: "center",
        },
        {
          text: "Штраф",
          value: "fact",
          displayText: (e) => this.$options.filters.numberSplit(e),
          align: "center",
        },
        {
          text: "Итого санкций",
          value: "percent",
          align: "center",
        },
      ],
      headers3: [
        { text: "Показатель", value: "Name" },
        {
          text: "Неоплата",
          value: "plan",
          displayText: (e) => this.$options.filters.numberSplit(e),
          align: "center",
        },
      ],
    };
  },
  computed: {
    computedHeaders() {
      const tmp = this.isSancation
        ? this.headers2
        : this.isNoPayment
        ? this.headers3
        : this.headers;
      return this.isCode
        ? [
            {
              text: "Коды МП",
              value: "Codes",
              dataType: "array",
              displayText: (e) => e,
            },
            ...tmp,
          ]
        : tmp;
    },
    isCode() {
      if (this.data?.length) return !!this.data.find((e) => e?.Codes?.length);
      else return false;
    },
    maxValue() {
      if (this.data?.length) return Math.max(...this.data.map((e) => e.fact));
      else return 1;
    },
  },
  methods: {
    clickRow(item) {
      this.tfomsParams.value.setLastLevel(item);
      this.$emit("input", item);
    },
  },
};
</script>
<style lang="scss">
.analysis-tfoms_candition-table {
  .v-data-table table tr td {
    cursor: pointer;
  }
}
</style>
